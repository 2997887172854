import * as Actions from "../../actions/types";

const INITIAL_STATE = {
  tickets: {
    get: {
      loading: false,
      reset: false,
      success: {
        ok: false,
        data: null,
      },
      failure: {
        error: false,
        message: "",
      },
    },
  },
};

const ticketNotificationReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Actions.GET_COMPANY_TICKETS_LOADING:
      return {
        ...state,
        tickets: {
          ...state.tickets,
          get: {
            ...state.tickets.get,
            loading: true,
            reset: false,
            success: {
              ...state.tickets.get.success,
              ok: false,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case Actions.GET_COMPANY_TICKETS_SUCCESS:
      return {
        ...state,
        tickets: {
          ...state.tickets,
          get: {
            ...state.tickets.get,
            loading: false,
            reset: false,
            success: {
              ...state.tickets.get.success,
              ok: true,
              data: action.payload,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case Actions.GET_COMPANY_TICKETS_FAILURE:
      return {
        ...state,
        tickets: {
          ...state.tickets,
          get: {
            ...state.tickets.get,
            loading: false,
            reset: false,
            success: {
              ...state.tickets.get.success,
              ok: false,
              data: null,
            },
            failure: {
              error: true,
              message: action.payload.message,
            },
          },
        },
      };
    case Actions.GET_COMPANY_TICKETS_RESET:
      return {
        ...state,
        tickets: {
          ...state.tickets.get,
          get: {
            ...INITIAL_STATE.tickets.post,
            reset: true,
          },
        },
      };
  }
  return state;
};

export default ticketNotificationReducers;
