import React, { createContext, useContext, useState, useEffect } from "react";
import { getItem } from "../SecureStorage";

// Create a context
const CompanyContext = createContext();

export const useCompany = () => {
  return useContext(CompanyContext);
};

export const CompanyProvider = ({ children, userDetails }) => {
  const [companyName, setCompanyName] = useState(null);
  const [username, setUsername] = useState(null);
  const [department, setDepartment] = useState(null);
  const [userType, setUserType] = useState(null);
  const [isConsumer, setIsConsumer] = useState(false);
  const [isSalesPerson, setIsSalesPerson] = useState(false);
  const [isProjectManager, setIsProjectManager] = useState(false);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const storedData = await getItem("userData");

        const userData = storedData ? JSON.parse(storedData) : null;

        if (userData) {
          setCompanyName(userData.companyName || null);
          setDepartment(userData.department || null);
          setUserType(userData.userType || null);
          setIsConsumer(userData.isConsumer || false);
          setUsername(userData.username || null);
          setIsSalesPerson(userData.isSalesPerson || false);
          setIsProjectManager(userData.isProjectManager || false);
          setUserId(userData.userId || null);
        }
      } catch (error) {
        console.error("Failed to fetch or parse user data:", error);
      }
    };

    fetchUserData();
  }, [userDetails]);

  return (
    <CompanyContext.Provider
      value={{
        companyName,
        username,
        department,
        userType,
        isConsumer,
        isSalesPerson,
        isProjectManager,
        userId,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};
