import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import ReduxThunk from "redux-thunk";
import * as serviceWorker from "./serviceWorker";
import reducers from "./reducers/index";
import { createStore, applyMiddleware, compose } from "redux";
import { BreakpointProvider } from "react-socks";
import "antd/dist/antd.css";
import GetLocation from "./components/SiteVisit/GetLocation";
require("dotenv").config("./env");

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  reducers,
  {},
  composeEnhancers(applyMiddleware(ReduxThunk))
);

ReactDOM.render(
  <BreakpointProvider>
    <Provider store={store}>
      <App />
    </Provider>
  </BreakpointProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
