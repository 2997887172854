import React from "react";
import { useDispatch } from "react-redux";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
  useHistory,
} from "react-router-dom";
import { clearItems, getItem, setItem } from "../../SecureStorage";
import "./app.route.style.less";
import * as actionCreators from "../../actions/auth-action/index";
import _ from "lodash";

import { message } from "antd";
import useOnlineStatus from "../../components/useOnlineStatus";

import SafEarthNavigator from "../../SafEarthNavigator";

import {
  authRoutes,
  consumerRoutes,
  noInternetRoutes,
  onBoardSupplierRoutes,
  rfqRoutes,
  salesRoutes,
  vendorRoutes,
} from "./lazyComponents.js";
import { CompanyProvider } from "../../context/CompanyContext.jsx";

const AppRoute = (props) => {
  const query = new URLSearchParams(props?.location?.search);
  const tokenFromURL = query.get("token");
  const pmtToken = query.get("pmtToken");
  const safearthNavigationPath = query.get("path");
  const subContractor = query.get("is_subcontractor");
  const customer = query.get("is_customer");
  const supplier = query.get("is_supplier");
  const rfq = query.get("is_rfq");
  const manufacturerName = query.get("supplier");
  const modelNumber = query.get("model_number");
  const quoteQuantity = query.get("quantity");
  const quotePrice = query.get("price");
  const format = query.get("format");

  const projectIdRedirect = query.get("project");
  const history = useHistory();

  if (subContractor) {
    setItem("subContractor", subContractor);
  }
  if (customer) {
    setItem("customer", customer);
  }
  const isOnline = useOnlineStatus();

  const dispatch = useDispatch();

  if (tokenFromURL && supplier === "true") {
    setItem("onboardSupplier", true);
    setItem("onboardSupplierToken", tokenFromURL);
    history.push("/onboard-suppliers");
    message.info("Please fill all the details!");
  }
  if (tokenFromURL && rfq === "true") {
    setItem("rfq", true);
    setItem("rfqToken", tokenFromURL);
    setItem("quotedItem", {
      manufacturerName,
      quoteQuantity,
      quotePrice,
      modelNumber,
      format,
    });
    history.push("/addQuote");

    message.info("Please fill all the details!");
  }

  if (tokenFromURL && projectIdRedirect) {
    dispatch(
      actionCreators.redirectToProjectDashboard(props, projectIdRedirect)
    );
  }

  if (tokenFromURL && !supplier && !projectIdRedirect) {
    dispatch(actionCreators.onBoardUser(props));
  }

  let secureStorage;
  let projectId;

  if (getItem("userData")) {
    secureStorage = JSON.parse(getItem("userData"));

    if (new Date(secureStorage?.tokenExpirationTime) < new Date()) {
      clearItems();
    }
  }
  if (getItem("projectId")) {
    projectId = JSON.parse(getItem("projectId"));
  }

  const isDraftProject = JSON.parse(getItem("isDraftProject"));

  const checkOnline = () => {
    return renderRoutes(noInternetRoutes, "/no-internet");
  };

  const renderRoutes = (routes, path) => (
    <Switch>
      {routes.map(({ path, component: Component, exact, props }) => (
        <Route
          key={path}
          path={path}
          exact={exact}
          render={(routeProps) => <Component {...routeProps} {...props} />}
        />
      ))}

      <Redirect from="*" to={path} />
    </Switch>
  );

  const renderComponents = () => {
    if (pmtToken && safearthNavigationPath) {
      return (
        <SafEarthNavigator token={pmtToken} path={safearthNavigationPath} />
      );
    }
    if (JSON.parse(getItem("onboardSupplierToken")) || supplier === "true") {
      return renderRoutes(onBoardSupplierRoutes, "/onboard-suppliers");
    }
    if (JSON.parse(getItem("rfqToken")) || rfq === "true") {
      return renderRoutes(rfqRoutes, "/addQuote");
    } else if (
      !secureStorage?.authToken ||
      secureStorage?.authToken === null ||
      new Date(secureStorage?.tokenExpirationTime) < new Date()
    ) {
      return renderRoutes(authRoutes, "/");
    } else if (secureStorage?.authToken) {
      if (secureStorage?.isConsumer) {
        return renderRoutes(consumerRoutes, "/customer-dashboard");
      } else if (
        secureStorage?.isSalesPerson ||
        secureStorage?.department === "Sales"
      ) {
        let redirectPath = props.location.pathname.includes("/crm")
          ? "/crm"
          : projectId
          ? "/projects/project-dashboard"
          : "/dashboard";
        return renderRoutes(salesRoutes, redirectPath);
      } else {
        let redirectPath = projectId
          ? isDraftProject
            ? "/dashboard"
            : "/projects/project-dashboard"
          : "/dashboard";
        return renderRoutes(vendorRoutes, redirectPath);
      }
    }
  };

  return (
    <div className="app-route">
      {isOnline ? renderComponents() : checkOnline()}
    </div>
  );
};

export default withRouter(AppRoute);
