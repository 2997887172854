import { AUTH_SUCCESS } from "../../actions/types";

// Initial State
const initialState = {
  data: null,
};

// Reducer
const authReducers = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };

    default:
      return state;
  }
};

export const authSuccess = (payload) => ({
  type: AUTH_SUCCESS,
  payload,
});

export default authReducers;
